:root {
  --primary-color: #c3892b;
  --text-gray: #4f4f4f;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

/* Reset Margins */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
picture {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: 'Barlow', sans-serif;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0 !important;
  font-family: 'Open Sans', sans-serif;
}

/* .glow {
  animation: glow 2s infinite;
}
@keyframes glow {
  0% {
    box-shadow: 0 0 5px var(--primary-color), 0 0 10px var(--primary-color),
      0 0 15px var(--primary-color), 0 0 20px var(--primary-color);
  }
  100% {
    box-shadow: 0 0 10px var(--primary-color), 0 0 20px var(--primary-color),
      0 0 30px var(--primary-color), 0 0 40px var(--primary-color);
  }
} */

/* Star Rating Start */
.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
}
.on {
  color: var(--primary-color);
}
.off {
  color: #ccc;
}

/* Star Rating End */

/* Utility Class */
.primary-color {
  color: var(--primary-color);
}

.gray-color {
  color: var(--text-gray);
}

.paddingright10 {
  padding-right: 10px;
}

.font-italic {
  font-style: italic;
}

.hr-style {
  height: 1px;
  background-color: rgba(195, 137, 43, 0.2);
  border: none;
  margin: 20px;
}

/* Normal Class */

.session-primary-text {
  font-size: 20px;
}

.session-secondary-text {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
}

.session-icon {
  background-color: rgba(195, 137, 43, 0.2) !important;
}

.home-masseuse-container {
  width: 100px;
}

.masseuse-container {
  box-shadow: 1px 11px 23px rgba(194, 135, 43, 0.2);
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.masseuse-info-container {
  padding: 40px 40px 40px 40px;
}

.masseuse-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.masseuse-image {
  height: 350px;
}

.masseuse-name {
  font-weight: 600;
  font-size: 1.75rem;
  /* line-height: 2.2rem; */
  color: var(--primary-color);
  margin-bottom: 15px;
}

.masseuse-name-span {
  font-weight: 500;
  color: var(--text-gray);
}

.masseuse-details {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.masseuse-details-span {
  color: var(--text-gray);
  font-weight: 500;
}

.session-container {
  box-shadow: 1px 11px 23px rgba(194, 135, 43, 0.2);
  border-radius: 10px;
}

.canadian-masseuses {
  background-image: linear-gradient(
      to bottom,
      rgba(57, 12, 32, 0.8),
      rgba(114, 200, 237, 0.8)
    ),
    url('./img/schedule.jpg');
  width: 100%;
  height: 400px;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.footer-links {
  color: var(--light-color);
}

.footer-links:hover {
  color: var(--primary-color);
}

.transaction-container {
  box-shadow: 1px 11px 23px rgba(194, 135, 43, 0.2);
  border-radius: 10px;
  padding: 32px !important;
}

.reward-card {
  background-color: var(--primary-color) !important;
  position: relative;
}

.reward-card p {
  color: var(--light-color);
}

.reward-card div {
  color: white;
}

.reward-card::before {
  content: '';
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 100%;
  background-color: hsl(0, 0%, 100%, 0.15);
  top: -250px;
  right: -150px;
  /* z-index: -1; */
}

.reward-card::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 100%;
  background-color: hsl(0, 0%, 100%, 0.075);
  bottom: -475px;
  right: -150px;
  /* z-index: -1; */
}

.appointment-card:hover {
  /* background-color: var(--primary-color); */
  transition: 0.3s all ease-in-out;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.appointment-card-selected {
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
}

.reward-point-purchase-card {
  margin-bottom: 10px;
  background-color: rgba(195, 137, 43, 0.1) !important;
  border: 1px solid #f9f4ea !important;
}

.reward-point-purchase-card:hover {
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  background-color: #f9f4ea;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color);
}

.reward-point-purchase-card-selected {
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
}

/* .locker-and-time-card {
  background: linear-gradient(
    94.89deg,
    #785726 -39.08%,
    #f3e283 24.09%,
    #ae8038 67.81%,
    #ae8038 106.54%,
    #f3e283 153.36%,
    #ae8038 206.75%,
    #f3e283 258.17%
  );
  border-radius: 10px;
  width: 150px;
  padding: 2px;
}

.locker-and-time-card h1 {
  text-align: center;
  font-size: 140px;
  line-height: 160px;
  color: #fcd354;
  background: #000;
  border-bottom: 30px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.locker-and-time-card h2 {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  color: #000;
  border-bottom: 30px;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px;
  margin: 10px;
} */

.locker-and-time-card-glow {
  background: #fcd354;
  border-radius: 10px;
  width: 100%;
  padding: 2px;
  box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  -webkit-box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  -moz-box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  animation: blinking 1s infinite;
  transition: all 1s ease-in-out;
}

.glow-effect {
  box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  -webkit-box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  -moz-box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  animation: blinking 1s infinite;
  transition: all 1s ease-in-out;
}

@keyframes blinking {
  0% {
    background: #ffffff;
    box-shadow: 0px 0px 10px 10px rgba(252, 211, 84, 0.75);
    -webkit-box-shadow: 0px 0px 10px 10px rgba(252, 211, 84, 0.75);
    -moz-box-shadow: 0px 0px 10px 10px rgba(252, 211, 84, 0.75);
  }
  20% {
    background: #fef6dd;
  }
  40% {
    background: #feedbb;
    box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
    -webkit-box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
    -moz-box-shadow: 0px 0px 20px 10px rgba(252, 211, 84, 0.75);
  }
  60% {
    background: #fde598;
  }
  80% {
    background: #fddc76;
    box-shadow: 0px 0px 30px 10px rgba(252, 211, 84, 0.75);
    -webkit-box-shadow: 0px 0px 30px 10px rgba(252, 211, 84, 0.75);
    -moz-box-shadow: 0px 0px 30px 10px rgba(252, 211, 84, 0.75);
  }
  100% {
    background: #fcd354;
  }
}

.locker-and-time-card-glow h1 {
  text-align: center;
  font-size: 170px;
  line-height: 190px;
  color: #000;
  background: #fcd354;
  border-bottom: 30px;
  border-radius: 10px;
  padding-bottom: 10px;
  animation: blinking2 1s infinite;
  transition: all 1s ease-in-out;
}

@keyframes blinking2 {
  0% {
    background: #ffffff;
  }
  20% {
    background: #fef6dd;
  }
  40% {
    background: #feedbb;
  }
  60% {
    background: #fde598;
  }
  80% {
    background: #fddc76;
  }
  100% {
    background: #fcd354;
  }
}

.locker-and-time-card-glow h2 {
  text-align: center;
  font-size: 36px;
  line-height: 40px;
  color: #fcd354;
  background-color: #000;
  border-bottom: 30px;
  font-weight: bold;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.it-is-time {
  background: radial-gradient(
    35.48% 437.2% at 50% 49.99%,
    #ffd856 0%,
    #dca727 92.97%
  );
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.it-is-time h1 {
  text-align: center;
  font-weight: bold;
  font-size: 44px;
}

.header-guest-notification-screen {
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  color: #fcd354;
}

.logo-guest-notification-screen {
  width: 200px;
}
/* 
@media (min-width: 2400px) {
  .logo-guest-notification-screen {
    width: 500px;
    margin-top: 30px;
    margin-bottom: -60px;
  }

  .header-guest-notification-screen {
    font-size: 140px;
    line-height: 150px;
    margin-bottom: -60px;
  }

  .locker-and-time-card {
    width: 500px;
    padding: 6px;
    margin-top: 100px;
  }
  .locker-and-time-card h1 {
    font-size: 340px;
    line-height: 360px;
    border-bottom: 60px;
    padding-bottom: 30px;
  }

  .locker-and-time-card h2 {
    font-size: 60px;
    line-height: 60px;

    padding: 30px;
    margin: 30px;
  }

  .locker-and-time-card-glow {
    width: 500px;
    padding: 6px;
    margin-top: 100px;
  }

  .locker-and-time-card-glow h1 {
    font-size: 240px;
    line-height: 240px;
    background: #fcd354;
    border-bottom: 60px;
    padding-bottom: 30px;
  }

  .locker-and-time-card-glow h2 {
    font-size: 40px;
    line-height: 50px;
    color: #fcd354;
    padding: 30px;
    margin: 30px;
  }
} */

/* Mobile Styles */
@media (max-width: 700px) {
  .masseuse-image {
    height: 250px;
    margin-bottom: 50px;
  }

  .home-masseuse-container {
    padding: 0px;
  }

  .canadian-masseuses {
    height: 200px;
    padding: 0px;
    text-align: center;
  }

  .masseuse-info-container {
    padding: 30px 30px 30px 30px;
  }
}

.circle {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  border-radius: 50%;
  border: 2px solid #54595f; /* Adjust the border color and size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.locker-number {
  /* Adjust the font size as needed */
  font-weight: 700;
}

.masseuse-card {
  background: linear-gradient(89.94deg, #f77063 0.05%, #fe5196 99.96%);
  border-radius: 15px;
}

.masseuse-card .score-container {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}

.masseuse-card .masseuse-name {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  color: #ffffff;
  text-transform: uppercase;
}

.masseuse-card .masseuse-overall-score {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  color: #ffffff;
}

.masseuse-card .masseuse-overall-score span {
  font-weight: 500;
  font-size: 40px;
}

.data-unavailable {
  background-color: #343a40;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
}

.ranking-card {
  background: linear-gradient(133.98deg, #eea711 -1.16%, #f2805a 99.03%);
}

.overall-card {
  background: linear-gradient(60.05deg, #75d979 -20.88%, #329134 101.26%);
  color: #ffffff;
  min-height: 507px;
}

.all-ques-chart {
  background: linear-gradient(
    54.97deg,
    #04e659 -3.94%,
    #57c4f9 107.55%,
    #129adc 107.55%
  );
  padding: 20px;
}

.all-ques-chart-container {
  background: #fff;
  padding: 20px;
}
